import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: () => import("@/views/login"),
  },
  {
    path: "/home",
    component: () => import("@/views/home"),
  },
  {
    path: "/record",
    component: () => import("@/views/record"),
  },
  {
    path: "/order",
    component: () => import("@/views/order"),
  },
  {
    path: "/set",
    component: () => import("@/views/set"),
  },
  {
    path: "/service",
    component: () => import("@/views/service"),
  },
  {
    path: "/company",
    component: () => import("@/views/company"),
  },
  {
    path: "/adduser",
    component: () => import("@/views/adduser"),
  },
];

const router = new VueRouter({
  routes,
});

export default router;
